import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { MuiAdd } from "../common/icons";
import {
  SelectFormField,
  SelectFormFieldOption,
  TextFormField,
  FileFormField,
} from "../common/components/FormFields";
import AgricultureIcon from "@mui/icons-material/Agriculture";
import { FieldArray } from "react-final-form-arrays";
import MuiDelete from "@mui/icons-material/Delete";

function toRange(count: number): number[] {
  return Array.from(new Array(count), (x, i) => i);
}

export enum RequestedMajorEquipmentType {
  SNOWMOBILE = "Snowmobile",
  BOAT = "Boat",
  ATV = "ATV",
  OUTBOARD_MOTOR = "Outboard Motor",
  CABIN_MATERIAL = "Cabin Material",
  OTHER = "Other",
}

const RequestedEquipmentMajorFormOptions: SelectFormFieldOption[] = [
  {
    value: RequestedMajorEquipmentType.OTHER,
    text: RequestedMajorEquipmentType.OTHER,
  },
  {
    value: RequestedMajorEquipmentType.SNOWMOBILE,
    text: RequestedMajorEquipmentType.SNOWMOBILE,
  },
  {
    value: RequestedMajorEquipmentType.BOAT,
    text: RequestedMajorEquipmentType.BOAT,
  },
  {
    value: RequestedMajorEquipmentType.ATV,
    text: RequestedMajorEquipmentType.ATV,
  },
  {
    value: RequestedMajorEquipmentType.OUTBOARD_MOTOR,
    text: RequestedMajorEquipmentType.OUTBOARD_MOTOR,
  },
  {
    value: RequestedMajorEquipmentType.CABIN_MATERIAL,
    text: RequestedMajorEquipmentType.CABIN_MATERIAL,
  },
];

export enum RequestedMinorEquipmentType {
  TOBOGGAN = "Toboggan",
  CHAINSAW = "Chainsaw",
  TENT = "Tent",
  GENERATOR = "Generator",
  CABIN_MATERIAL = "Cabin Material",
  OUTBOARD_MOTOR = "Outboard Motor",
  OTHER = "Other",
}

const RequestedEquipmentMinorFormOptions: SelectFormFieldOption[] = [
  {
    value: RequestedMinorEquipmentType.OTHER,
    text: RequestedMinorEquipmentType.OTHER,
  },
  {
    value: RequestedMinorEquipmentType.TOBOGGAN,
    text: RequestedMinorEquipmentType.TOBOGGAN,
  },
  {
    value: RequestedMinorEquipmentType.CHAINSAW,
    text: RequestedMinorEquipmentType.CHAINSAW,
  },
  {
    value: RequestedMinorEquipmentType.TENT,
    text: RequestedMinorEquipmentType.TENT,
  },
  {
    value: RequestedMinorEquipmentType.GENERATOR,
    text: RequestedMinorEquipmentType.GENERATOR,
  },
  {
    value: RequestedMinorEquipmentType.CABIN_MATERIAL,
    text: RequestedMinorEquipmentType.CABIN_MATERIAL,
  },
  {
    value: RequestedMinorEquipmentType.OUTBOARD_MOTOR,
    text: RequestedMinorEquipmentType.OUTBOARD_MOTOR,
  },
];

export interface RequestedEquipment {
  description?: string;
  cost?: string;
  requestedContribution?: string;
  type?: RequestedMajorEquipmentType | RequestedMinorEquipmentType;
  quote?: File;
}

export const DEFAULT_REQUESTED_EQUIPMENT_VALUES = {
  description: "",
  cost: "",
  requestedContribution: "",
  type: undefined,
  quote: undefined,
};

export interface RequestedEquipmentPropsList {
  type: "major" | "minor";
}

export const RequestedEquipment: React.FC<RequestedEquipmentPropsList> = ({
  type,
}) => {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  const namespace = type + "RequestedEquipment";
  return (
    <Box>
      <Box mb={0.5}>
        <Typography variant="h6">
          {type === "major"
            ? "Major Equipment ($7500 maximum)"
            : "Minor Equipment ($2500 maximum)"}
        </Typography>
      </Box>
      <FieldArray<RequestedEquipment>
        name={type + "RequestedEquipment"}
        render={({ fields }) => (
          <Stack gap={2}>
            {fields.map((name, index) => (
              <Stack direction={"row"} gap={2}>
                <Grid container spacing={2} my={2}>
                  <Grid item xs={12} md={3}>
                    <TextFormField
                      name={`${name}.description`}
                      label="Description"
                      size="small"
                      icon={<AgricultureIcon />}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextFormField
                      name={`${name}.cost`}
                      label="Cost"
                      size="small"
                      noIcon={md}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextFormField
                      name={`${name}.requestedContribution`}
                      label="Requested Contribution"
                      size="small"
                      noIcon={md}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <SelectFormField
                      name={`${name}.type`}
                      label="Equipment Type"
                      noIcon={md}
                      size="small"
                      options={
                        type === "major"
                          ? RequestedEquipmentMajorFormOptions
                          : RequestedEquipmentMinorFormOptions
                      }
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FileFormField name={`${name}.quote`} />
                  </Grid>
                </Grid>
                <Button
                  variant={"outlined"}
                  color="error"
                  onClick={() => fields.remove(index)}
                >
                  <MuiDelete />
                </Button>
              </Stack>
            ))}
            <Box ml={5}>
              <Button
                startIcon={<MuiAdd />}
                variant="outlined"
                onClick={() =>
                  fields.push({ ...DEFAULT_REQUESTED_EQUIPMENT_VALUES })
                }
              >
                Add Equipment
              </Button>
            </Box>
          </Stack>
        )}
      />
    </Box>
  );
};
