import React from "react";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { FormSpy, useField } from "react-final-form";
import { TextFormField } from "../common/components/FormFields";
import { AutocompleteFormField } from "../common/components/FormFields/AutocompleteFormField";
import { RegionSelector } from "../common/components/FormFields/InternationalAddressFields/RegionSelector";
import { provincesAndTerritoris } from "../common/components/FormFields/InternationalAddressFields/places";

const dotty = require("dotty");

const OTHER = "Other";

export interface IHAPAddressFieldsProps {
  namespace: string;
  label?: string;
  allowOtherCountries?: boolean;
  required?: boolean;
}

export function IHAPAddressFields({
  namespace,
  required,
  label,
}: IHAPAddressFieldsProps) {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  const communities = [
    "Aklavik",
    "Inuvik",
    "Paulatuk",
    "Sachs Harbour",
    "Tuktoyaktuk",
    "Ulukhaktok",
    "Other",
  ];

  const {
    input: { value: selectedCountry },
  } = useField<string>(`${namespace}.country`);

  return (
    <Grid container spacing={0.5} pt={1}>
      {label && (
        <Grid item xs={12}>
          <Box ml={5}>
            <Typography>{label}</Typography>
          </Box>
        </Grid>
      )}
      <Grid item md={6} xs={12}>
        <TextFormField
          name={`${namespace}.line1`}
          required={required}
          label="Line 1"
          size="small"
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextFormField
          name={`${namespace}.line2`}
          label="Line 2"
          size="small"
          noIcon={md}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <AutocompleteFormField
          name={`${namespace}.region`}
          required={required}
          options={provincesAndTerritoris}
          label={"Province/Territory"}
          size="small"
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <AutocompleteFormField
          name={`${namespace}.community`}
          required={required}
          options={communities}
          noIcon
          label="Community"
          size="small"
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <TextFormField
          name={`${namespace}.postalCode`}
          label="Postal Code"
          required={required}
          size="small"
          noIcon={md}
        />
      </Grid>
    </Grid>
  );
}
