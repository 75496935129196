import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { TextFormField } from "../common/components/FormFields";
import PersonIcon from "@mui/icons-material/Person";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import { IHAPAddressFields } from "./IHAPAddressFields";

export interface RegistrantInfoProps {}

export const RegistrantInfo: React.FC<RegistrantInfoProps> = ({}) => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <Box>
      <Typography variant="h6">Registrant's Information</Typography>
      <Grid container spacing={2} mt={-1}>
        <Grid item xs={12} sm={6}>
          <TextFormField
            name="registrantFirstName"
            label="First Name"
            required
            icon={<PersonIcon />}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFormField
            name="registrantLastName"
            label="Last Name"
            required
            noIcon={sm}
          />
        </Grid>
        <Grid item xs={12}>
          <IHAPAddressFields
            namespace="address"
            label="Address"
            required={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFormField
            name="registrantEmail"
            label="Email"
            required
            icon={<ContactMailIcon />}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFormField
            name="registrantPhoneNumber"
            label="Phone"
            required
            noIcon={sm}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
